
.container {
  padding-top: 20px;
  padding-bottom: 20px !important;
}
.buttons > .dropdown {
  margin-right: 10px;
}

@media only screen and (min-width: 1024px) {
  .tinted-dropdown-item {
    background: rgb(244, 244, 244, 0.8);
  }
}

@media only screen and (max-width: 1024px) {
  .dropdown-item {
    padding: 16px 24px;
    &.tinted-dropdown-item {
      padding: 8px 24px;
    }
  }
}
